@-webkit-keyframes maskAnimation {
  0% { transform: translateY(0); }
  50% { transform: translateY(-2px); }
  100% { transform: translateY(0px); }
}
@-moz-keyframes maskAnimation {
  0% { transform: translateY(0); }
  50% { transform: translateY(-2px); }
  100% { transform: translateY(0px); }
}
@keyframes maskAnimation {
  0% { transform: translateY(0); }
  50% { transform: translateY(-2px); }
  100% { transform: translateY(0px); }
}

@-webkit-keyframes wave1Animation {
  0% { transform: translate(0); }
  50% { transform: translate(0.5px, -0.25px); }
  100% { transform: translate(0px); }
}
@-moz-keyframes wave1Animation {
  0% { transform: translate(0); }
  50% { transform: translate(0.5px, -0.25px); }
  100% { transform: translate(0px); }
}
@keyframes wave1Animation {
  0% { transform: translate(0); }
  50% { transform: translate(0.5px, -0.25px); }
  100% { transform: translate(0px); }
}

@-webkit-keyframes wave2Animation {
  0% { transform: translate(0); }
  50% { transform: translate(-0.5px, 0.25px); }
  100% { transform: translate(0px); }
}
@-moz-keyframes wave2Animation {
  0% { transform: translate(0); }
  50% { transform: translate(-0.5px, 0.25px); }
  100% { transform: translate(0px); }
}
@keyframes wave2Animation {
  0% { transform: translate(0); }
  50% { transform: translate(-0.5px, 0.25px); }
  100% { transform: translate(0px); }
}

@-webkit-keyframes wave3Animation {
  0% { transform: translate(0); }
  50% { transform: translate(-0.25px, 0.25px); }
  100% { transform: translate(0px); }
}
@-moz-keyframes wave3Animation {
  0% { transform: translate(0); }
  50% { transform: translate(-0.25px, 0.25px); }
  100% { transform: translate(0px); }
}
@keyframes wave3Animation {
  0% { transform: translate(0); }
  50% { transform: translate(-0.25px, 0.25px); }
  100% { transform: translate(0px); }
}

svg.mask-container {
  position: relative;
  z-index: 10;
  width: 100%;
  margin: -2px 0;
  background: white;
}
svg path.black {
  fill: black;
}
svg path.white {
  fill: white;
}
svg.mask-container path.figure {
  fill: none;
  stroke: black;
  stroke-miterlimit: 10;
  stroke-width: 1.04;
}
svg.mask-container g.center {
  transform: translate(40%, 25%);
}

svg.mask-container g.mask {
  -webkit-animation: maskAnimation 1500ms ease-in-out infinite both;
  -moz-animation:    maskAnimation 1500ms ease-in-out infinite both;
  -ms-animation:     maskAnimation 1500ms ease-in-out infinite both;
  -o-animation:      maskAnimation 1500ms ease-in-out infinite both;
  animation:         maskAnimation 1500ms ease-in-out infinite both;
}

svg.mask-container path.wave-1 {
  -webkit-animation: wave1Animation 1500ms ease-in-out infinite both;
  -moz-animation:    wave1Animation 1500ms ease-in-out infinite both;
  -ms-animation:     wave1Animation 1500ms ease-in-out infinite both;
  -o-animation:      wave1Animation 1500ms ease-in-out infinite both;
  animation:         wave1Animation 1500ms ease-in-out infinite both;
}

svg.mask-container path.wave-2 {
  -webkit-animation: wave2Animation 1500ms ease-in-out infinite both;
  -moz-animation:    wave2Animation 1500ms ease-in-out infinite both;
  -ms-animation:     wave2Animation 1500ms ease-in-out infinite both;
  -o-animation:      wave2Animation 1500ms ease-in-out infinite both;
  animation:         wave2Animation 1500ms ease-in-out infinite both;
}

svg.mask-container path.wave-3 {
  -webkit-animation: wave3Animation 1500ms ease-in-out infinite both;
  -moz-animation:    wave3Animation 1500ms ease-in-out infinite both;
  -ms-animation:     wave3Animation 1500ms ease-in-out infinite both;
  -o-animation:      wave3Animation 1500ms ease-in-out infinite both;
  animation:         wave3Animation 1500ms ease-in-out infinite both;
}

svg.rain,
svg.falling {
  width: 100%;
  background: white;
  margin: -2px 0;
}

svg.rain {
  margin-top: -30%;
}

svg.illusions {
  background: black
}

svg.illusions > g {
  opacity: 0;
  transition: opacity 300ms ease-in;
}

svg.illusions .head {
  transform: scale(0.9);
  transform-origin: center;
}

svg.illusions.phase-1 .child-1 { opacity: 1; }
svg.illusions.phase-2 .child-2 { opacity: 1; }
svg.illusions.phase-3 .child-3 { opacity: 1; }
svg.illusions.phase-4 .child-4 { opacity: 1; }
svg.illusions.phase-5 .head { opacity: 1; }
svg.illusions.phase-6 .bull { opacity: 1; }
svg.illusions.phase-7 .masks { opacity: 1; }
svg.illusions.phase-8 .masks-2 { opacity: 1; }

svg.illusions .a {
  fill: none;
  stroke: #fff;
  stroke-miterlimit: 10;
  stroke-width: 0.5px;
}
svg.illusions .b {
  fill: #fff;
}
svg.illusions .c {
  fill: none;
  stroke-miterlimit: 10;
  stroke: #000;
  stroke-width: 0.79px;
}
svg.illusions .d {
  fill: #fff;
  stroke: #000;
  stroke-miterlimit: 10;
  stroke-width: 0.79px;
}
svg.illusions .e {
  fill: #000;
  stroke: #fff;
  stroke-miterlimit: 10;
  stroke-width: 0.48px;
}
svg.illusions .f {
  fill: none;
  stroke: #fff;
  stroke-miterlimit: 10;
  stroke-width: 0.51px;
}
svg.illusions .h {
  fill: #000;
}

svg.bikes {
  margin-top: -1em;
}

svg.bikes image {
  opacity: 0;
  transition: all 200ms ease-in-out;
}
svg.bikes .noise {
  opacity: 1;
}

svg.bikes.phase-1 .noise { opacity: 0; }
svg.bikes.phase-1 .image-1 { opacity: 1; }
svg.bikes.phase-1 .image-2 { opacity: 1; }
svg.bikes.phase-1 .image-3 { opacity: 1; }
svg.bikes.phase-1 .image-4 { opacity: 1; }

svg.bikes.phase-2 .noise { opacity: 0; }
svg.bikes.phase-2 .image-1 { opacity: 1; transform: translate(-25%, -25%); }
svg.bikes.phase-2 .image-2 { opacity: 1; transform: translate(-25%, 25%); }
svg.bikes.phase-2 .image-3 { opacity: 1; transform: translate(25%, -25%); }
svg.bikes.phase-2 .image-4 { opacity: 1; transform: translate(25%, 25%); }

svg.bikes.phase-3 .noise { opacity: 0; }
svg.bikes.phase-3 .image-1 { opacity: 0; }
svg.bikes.phase-3 .image-2 { opacity: 0; }
svg.bikes.phase-3 .image-3 { opacity: 0; }
svg.bikes.phase-3 .image-4 { opacity: 0; }
svg.bikes.phase-3 .pigeon { opacity: 1; }

svg.regularity {
  top: 0 !important;
  bottom: 0 !important;
}
svg.regularity > g {
  opacity: 0;
  transition: all 100ms ease-in-out;
}

svg.regularity.phase-0 .mask { opacity: 1; }
svg.regularity.phase-1 .figure-1 { opacity: 1; }
svg.regularity.phase-2 .figure-2 { opacity: 1; }
svg.regularity.phase-3 .head { opacity: 1; }
svg.regularity.phase-4 .snakes { opacity: 1; }
svg.regularity.phase-5 .monkey { opacity: 1; }
svg.regularity.phase-6 .finger { opacity: 1; }
