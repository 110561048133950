* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  -webkit-overflow-scrolling: touch;
}

html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-weight: 200;
  font-size: 14px;
  font-family: Montserrat, Helvetica, sans-serif;
  background: black;
}

main {
  position: relative;
  width: 100%;
  height: 100%;
}

button {
  font-family: Montserrat, Helvetica, sans-serif;
}

.likely {
  width: 100%;
  min-height: 50px;
  margin: -20px 0 0 0 !important;
  background: white;
  text-align: center;
}
.likely div {
  margin: 0 0.5em 1em 0.5em !important;
  filter: grayscale(1);
}
.likely div:hover {
  filter: none;
}

/* ------------------- */
/* First start section */
/* ------------------- */
@import './sections/start.css';

/* -------------------- */
/* Second intro section */
/* -------------------- */
@import './sections/sections.css';

/* ----------------------- */
/* Third questions section */
/* ----------------------- */
@import './sections/question.css';

/* ----------------------------- */
/* All svg arts between sections */
/* ----------------------------- */
@import './sections/arts.css';

footer .content {
  width: 100%;
  display: flex;
  align-items: flex-end;
  color: white;
  padding: 0 2em;
  font-size: 3em;
}
footer a {
  display: inline-block;
  text-decoration: none;
  color: white !important;
  border-bottom: 1px solid black;
}
footer a:hover {
  border-bottom-color: white;
}
footer a.park:hover {
  border-bottom-color: black;
}
footer .content .logo svg {
  fill: white;
  height: 2.3em;
  margin-bottom: -0.3em;
}
footer .content .park {
  margin-left: auto;
}
footer .content .park img {
  height: 2em;
  object-fit: cover;
}
footer .authors {
  margin-left: 1em;
  font-size: 0.42em;
}
footer > svg {
  width: 106%;
  margin-left: -6%;
}
