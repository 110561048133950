section.text {
  position: relative;
  display: flex;
  padding: 3em 6em;
  padding-bottom: 0;
  background: black;
  color: white;
}

section h1 {
  margin: 0;
  margin-bottom: 0.8em;
  font-size: 3em;
}

section h2 {
  margin: 0;
  font-size: 2em;
}

section.text .content {
  width: 50%;
  padding-right: 1em;
  min-width: 200px;
  max-width: 600px;
  font-size: 1.8em;
}

section.text .content > div {
  margin-bottom: calc(20vh + 20vw);
  line-height: 1.6em;
}

section.text .content .quote {
  padding: 1em 0;
  border-top: 2px solid white;
  border-bottom: 2px solid white;
}

section.text .content a {
  color: white;
  text-decoration: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}
section.text .content a:hover {
  border-bottom-color: white;
}

section.text .animation svg {
  position: absolute;
  top: 3em;
  right: 0;
  width: 50%;
}

section.text .animation svg.fixed {
  position: fixed;
}
section.text .animation svg.bottom {
  top: unset !important;
  bottom: 3em;
}

/* all svg styles (16 groups) */
.intro .animation svg * {
  transform-origin: center;
  transition: opacity 250ms ease-in-out,
              transform 250ms ease-in-out;
}
.intro .animation svg g.group {
  transition: opacity 150ms ease-in-out;
}
.intro .animation svg .white {
  fill: white;
}
.intro .animation svg .custom {
  fill: none;
  stroke: black;
  stroke-miterlimit: 10;
  stroke-width: 0.79px;
}
.intro .animation svg .shift {
  transform: translate(10px, 25px) !important;
}

svg.edge {
  position: absolute;
  width: 100%;
  bottom: -3em;
  left: 0;
}
svg.edge.relative {
  position: relative;
  margin-bottom: -3em;
}
svg.edge.reversed {
  bottom: unset;
  top: -3em;
  transform: scale(-1);
}

svg.edge path {
  fill: white;
}

section.coin {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 3em 6em;
  padding-bottom: 9em;
  background: black;
  color: white;
}
section.coin h1 {
  width: 100%;
}
section.coin .task {
  width: 50%;
  margin-top: 2em;
  font-size: 1.4em;
  line-height: 1.5em;
}

@keyframes toss {
  0% { bottom: 14em;}
  50% { bottom: 30em;}
  100% { bottom: 14em;}
}
section.coin .action {
  height: 25em;
  width: 50%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
section.coin .animation {
  position: absolute;
  overflow: hidden;
  bottom: 14em;
  height: 150px;
  width: 150px;
  left: calc(50% - 75px);
  border-radius: 50%;
  background: black;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotateY(0deg) rotateX(0deg);
  transition: transform 1s ease-in-out;
}
section.coin .animation.flip {
  transform: rotateY(720deg) rotateX(720deg);
}
section.coin .animation.toss {
  background: #6e6e6e;
  animation: toss .7s forwards ease-in-out;
}
section.coin .animation img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}
section.coin .animation img.eagle {
  opacity: 1;
}

section.coin .stats {
  width: 240px;
  height: 10em;
  font-size: 1.2em;
  line-height: 1.6em;
}
section.coin .action button {
  white-space: nowrap;
  display: block;
  background: black;
  border: 1px solid white;
  color: white;
  font-size: 1.2em;
  padding: 0.4em 1em;
  margin-bottom: 1em;
  outline: none;
  cursor: pointer;
}
section.coin .stats button.disabled {
  opacity: 0.3;
  pointer-events: none;
  cursor: default;
}
section.coin .stats button:hover {
  background: white;
  color: black;
}
section.coin .stats .result {
  display: flex;
  justify-content: center;
  align-items: center;
}
section.coin .stats .result > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 0.75em;
  font-size: 1.2em;
}
section.coin .stats .result > div span {
  position: relative;
  display: block;
  font-size: 3em;
  line-height: 1.1em;
}
section.coin .stats .result > div span:after {
  content: '%';
  font-size: 0.4em;
  line-height: 1em;
}
section.coin .stats .count {
  width: 100%;
  text-align: center;
  font-size: 1.2em;
  margin-top: 0.75em;
}


section.center {
  padding-bottom: 8em;
}
section.center .content {
  width: 75%;
  max-width: 800px;
  margin: 0 auto;
  padding: 0;
  line-height: 1.6em;
}
section.center .content img {
  display: block;
  width: 70%;
  margin: 4em auto 0 auto;
}

section.text.inverse .content {
  margin-left: 50%;
}
section.text.inverse .animation svg {
  left: 0;
}
