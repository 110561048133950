.start {
  position: relative;
  width: 100%;
  min-height: 100vh;
  background: black;
}

.start .header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0.85em;
  z-index: 1000;
  color: black;
  font-weight: bold;
  font-size: 4em;
  transition: color 200ms ease-in-out;
}

.start .header .logo svg {
  object-fit: contain;
  width: 2.6em;
}

.start .header .logo span {
  font-style: italic;
}

.start .header .year {
  position: relative;
  padding: 0.2em 0;
  margin-top: -0.2em;
  writing-mode: vertical-rl;
  transform: scale(-1, -1);
}

.start .header .year:after {
  content: '';
  position: absolute;
  top: 0;
  height: 100%;
  left: 50%;
  transform: translateX(-4px);
  width: 3px;
  background: black;
}

.start > svg {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: white;
}

.start > svg ellipse {
  transform-origin: center;
  transform: rotate(-25deg);
}

.start.dark .header {
  color: white;
}
.start.dark .header svg .a {
  fill: white;
}
.start.dark .header .year:after {
  background: white;
}
