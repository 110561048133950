.question {
  position: relative;
  width: 100%;
  padding: 3em 6em;
  margin-top: -2px;
  background: white;
  color: black;
  overflow: hidden;
}

@media(min-width: 1024px) {
  .question {
    padding: 3em 12em;
  }
}

.question.black {
  background: black;
  color: white;
}

.question.shadow {
  box-shadow: 0px 10px 20px white;
}

.question h2 {
  width: 50%;
}

.question .content {
  position: relative;
  display: flex;
  padding-bottom: 1em;
}

.question .content .task {
  width: 50%;
  min-height: 170px;
  padding: 1.6em 0;
  font-size: 1.3em;
  line-height: 1.5em;
}

.question .content svg {
  position: absolute;
  bottom: 2.6em;
  height: 170px;
  max-width: 50%;
}

.question .content svg .black {
  fill: #1f1f1f;
}

.question .content svg.ship {
  right: 50vw;
  transform: translateX(160%);
  transition: all 500ms ease-in-out;
}
.question .content svg.ship circle {
  opacity: 0;
  transition: all 10ms linear;
}
.question .content svg.ship.shifted {
  right: 0;
  transform: translateX(0);
}
.question .content svg.ship.shifted circle {
  opacity: 1;
  transition: all 10ms linear 500ms;
}

.question .content svg.pistol {
  right: 0;
  transform: translateX(150%);
  transition: all 200ms ease-in-out;
}
.question .content svg.pistol path {
  opacity: 0;
  transition: all 1ms ease-in-out;
}
.question .content svg.pistol.shifted {
  transform: translateX(0);
}
.question .content svg.pistol.shifted path {
  opacity: 1;
  transition: all 200ms ease-in-out 300ms;
}
.question .content svg.earth {
  right: 10%;
}
.question .content svg.earth circle.dot {
  opacity: 0;
}
.question .content svg.earth.shifted circle.dot {
  opacity: 1;
}

.question .content svg.moon {
  right: 10%;
  bottom: -1em;
}
.question .content svg.seasons {
  right: 10%;
}

.question .answers {
  display: flex;
  padding: 0 0 1em 1px;
}

.question .answers button {
  flex: 1;
  display: flex;
  margin-right: -1px;
  border: 1px solid #ddd;
  background: white;
  outline: none;
  font-size: 1.8em;
  font-weight: bold;
  text-align: left;
  padding: 0.8em 1.2em;
  cursor: pointer;
  transition: all 200ms ease-in-out;
}
.question .answers button:disabled {
  cursor: default;
  color: black;
}
.question .answers button:not(:disabled):hover {
  background: black;
  color: white;
}
.question .answers button.selected {
  background: black;
  color: white;
}

.question.black .answers button {
  background: black;
  color: white;
}
.question.black .answers button:disabled {
  color: white;
}
.question.black .answers button:not(:disabled):hover {
  background: white;
  color: black;
}
.question.black .answers button.selected {
  background: white;
  color: black;
}

.question .hint {
  display: flex;
  flex-wrap: wrap;
  max-height: 0;
  margin-top: 1em;
  line-height: 1.5em;
  font-size: 1.1em;
  overflow: hidden;
  transition: all 250ms ease-in-out;
}

.question .hint .result {
  width: 100%;
  margin-bottom: 0.8em;
  font-size: 1.65em;
  font-weight: bold;
  font-style: italic;
}

.question .hint .result.hidden {
  display: none;
}

.question .hint img {
  width: 100%;
  object-fit: contain;
}

.question .hint .iframe {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
}
.question .hint .iframe iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.question .hint p {
  margin: 0;
  padding: 0;
  margin-bottom: 6px;
}

.question .hint .column {
  width: 50%;
  padding-right: 20px;
}

.question.answered .hint {
  max-height: 150vh;
}

